"use client";
import { PropsWithChildren, useState } from "react";

import { redirect, useSearchParams } from "next/navigation";
import { useCookies } from "react-cookie";
import { FadeLoader } from "react-spinners";

import { useClientCartCart, useClientCartOrder } from "@/storage";
import { saveOrderEmail } from "@/storage/clientSurveyOrderEmail";
import { withCsr, withSuspense } from "@/utils";
import { theme } from "@/utils/color";
import { useOnce } from "@/utils/hooks";

import {
  completeAnalytics,
  completeCocoda,
  completeSN,
  triggerFromPage,
  useClearStorage,
} from "./helpers";

export interface ResetCheckoutProps extends PropsWithChildren {}

export const ResetCheckout = withCsr(
  withSuspense(function ResetCheckout({ children }: ResetCheckoutProps): React.ReactNode {
    const searchParams = useSearchParams();
    const clearStorage = useClearStorage();
    const { order } = useClientCartOrder();
    const { cart } = useClientCartCart();
    const [storageCleared, setStorageCleared] = useState(false);
    const [, setCookie] = useCookies(["complete_cart_item", "shopify_cart_override"]);
    // fromは一般注文、formはLPからの注文
    const searchParamFrom = searchParams.get("from") || searchParams.get("form");

    if (
      !storageCleared && // ストレージがクリアされた後は、リダイレクトしない
      (!order || !Object.keys(order).length || !cart)
    ) {
      redirect("/checkout");
    }

    useOnce(() => {
      if (!cart || !order) {
        // 直前のifでorderとcartが存在することが保証されているため、ここに入ることはないが
        // 型を合わせるために追加
        return;
      }
      const baseDomain = location.hostname.match(/\.basefood.+?$/)?.[0];
      setCookie("shopify_cart_override", "", {
        domain: baseDomain,
      });

      triggerFromPage({ searchParamFrom, order, cart });

      const completeCartItems = completeAnalytics({ order, cart });
      setCookie("complete_cart_item", completeCartItems, {
        domain: baseDomain,
      });

      completeSN();

      completeCocoda(order.id);
      setStorageCleared(true);
      // アンケート送信のため、order emailを保存する
      saveOrderEmail(order.email);
      clearStorage();
    });

    return (
      <>
        {children}
        {order && (
          <>
            {order.email && (
              <p hidden className="conversion_value1">
                {order.email}
              </p>
            )}
            {order.shipping_address && (
              <>
                <p hidden className="conversion_value2">
                  {order.shipping_address.phone}
                </p>
                <p hidden className="conversion_value3">
                  {order.shipping_address.first_name}
                </p>
                <p hidden className="conversion_value4">
                  {order.shipping_address.last_name}
                </p>
                <p hidden className="conversion_value5">
                  {order.shipping_address.zip}
                </p>
                <p hidden className="conversion_value6">
                  {order.shipping_address.converted_province}
                </p>
                <p hidden className="conversion_value7">
                  {order.shipping_address.city}
                  {order.shipping_address.address_line1}
                </p>
                <p hidden className="conversion_value8">
                  {order.shipping_address.address_line2}
                </p>
              </>
            )}
          </>
        )}
      </>
    );
  }),
  <FadeLoader color={theme.yellow} loading />
);
