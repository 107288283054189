import { ProductLargeImage } from "../../product/ProductLargeImage";

export function NewLaunchedKoshian(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/basebread/koshian"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_bb_koshian_W1920_H1080_0805.jpg",
        alt: "BASE BREAD こしあん",
        aspectRatio: "1920/1080",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_bb_koshian_W1080_H1080_0805.jpg",
        alt: "BASE BREAD こしあん",
        aspectRatio: "1080/1080",
      }}
    />
  );
}
