"use client";
import clsx from "clsx";

import { Image } from "@/components/displays";
import { Breadcrumb } from "@/components/layouts";

import styles from "./HeaderSection.module.scss";

const items = [
  {
    path: "/",
    title: "HOME",
  },
  {
    path: "/mypage",
    title: "マイページ",
  },
];

export function HeaderSection(): React.ReactNode {
  return (
    <section className={styles.root}>
      <div className={clsx("container", styles.breadcrumb)}>
        <Breadcrumb current="マイルプログラム" items={items} />
      </div>
      <Image
        containerClassName={clsx("clear__pc")}
        className={clsx(styles.headerImage)}
        alt="マイルプログラム画像"
        src="https://asset.basefood.co.jp/images/bg/bg_mileprogram_sp.png"
        aspectRatio="1200 / 1600"
      />
      <Image
        containerClassName={clsx("clear__sp")}
        className={clsx(styles.headerImage)}
        alt="マイルプログラム画像"
        src="https://asset.basefood.co.jp/images/bg/bg_mileprogram_pc.png"
        aspectRatio="2560 / 1080"
      />
    </section>
  );
}
