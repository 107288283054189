"use client";
import Link from "next/link";

import { Image } from "@/components/displays";
import { withCsr } from "@/utils";

import bnrYakisobaSp from "$/images/banner/bnr_yakisoba_sp.jpg";

import styles from "./Announcements.module.scss";
import { useImageLinks } from "./helpers";
import { ImageLinkList } from "../ImageLinkList";

export const Announcements = withCsr(function Announcements(): React.ReactNode {
  const { imageLinks } = useImageLinks();

  return (
    <div>
      <ImageLinkList images={imageLinks} cols={2} flow={{ sp: "column", pc: "row" }} />

      <div className="pd__bottom__s">
        <Link href="/products/deli/baseyakisoba/sauceyakisoba" className={styles.link}>
          <Image
            src={bnrYakisobaSp}
            alt="BASE YAKISOBA リニューアル"
            containerClassName={styles.imageContainer}
            className={styles.image}
          />
        </Link>
      </div>
    </div>
  );
});
