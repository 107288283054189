import clsx from "clsx";
import Link from "next/link";

import { LinkButton } from "@/components/buttons";

import styles from "./CautionSection.module.scss";

export function CautionSection(): React.ReactNode {
  return (
    <section className={styles.root}>
      <div className="container pd__bottom__xl">
        <div className="row mg__bottom__l">
          <div className="col-12 col-m-10 col-offset-m-1">
            <div className={styles.container}>
              <div className="row">
                <div className="col-12 col-m-10 col-offset-m-1">
                  <h3 className="text__xl text__bold text__center text__yellow mg__bottom__m">
                    注意事項
                  </h3>

                  <ul className="text__s">
                    <li className={clsx("text__bold", styles.listItem)}>
                      購入完了後は、ポイント購入代金の返金には応じられません。
                    </li>
                    <li className={styles.listItem}>保有できるポイントの上限は100,000ptです。</li>
                    <li className={styles.listItem}>
                      ポイントの有効期限は、ポイントを最後に獲得した日から2年間です。ポイントを追加購入、あるいは追加取得すると、ポイントすべての有効期限が2年後に更新されます。
                    </li>
                    <li className={styles.listItem}>
                      ポイントによる決済を取り消した場合、決済に利用されたポイントは原則としてポイントで返還され、現金による返還は行われません。
                    </li>
                    <li className={styles.listItem}>
                      いかなる場合でもポイントを換金することはできません。
                    </li>
                    <li className={styles.listItem}>
                      詳細は
                      <a className={styles.link} href="https://basefood.co.jp/fund_settlement">
                        資金決済法に基づく表示
                      </a>
                      、
                      <Link className={styles.link} href="/terms">
                        ポイント利用規約
                      </Link>
                      をご確認ください
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text__center">
            <LinkButton arrow="angle__left" color="gray" href="/mypage">
              マイページに戻る
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
}
