import { isAxiosError } from "axios";
import { DeepPartial } from "react-hook-form";
import { scroller } from "react-scroll";

import { PaymentDataValues } from "@/components/domains/checkout-form";
import { PointPlanValues } from "@/components/domains/yearplan/PointPlanForm/schema";
import { htmlToast } from "@/components/feedbacks";
import {
  postPointProductConfirm,
  postPointProductCreate,
} from "@/generated/axios-functions/payseAPI";
import {
  PointProduct,
  PointProductType,
  PostPointProductCreate400,
  PostPointProductCreate400OneOf,
  PostPointProductCreate400OneOfTwoException,
} from "@/generated/open-api/schemas";
import { PaymentMethod } from "@/models/payment/consts";
import { PointProductPlan, PointProductPlanValue } from "@/models/pointProduct/type";
import { fireKarteEvent } from "@/utils";
import {
  deleteFormDataForAmazonPay,
  fireAmazonPayAction,
  getAmazonCheckoutUrl,
  getFormDataForAmazonPay,
  setFormDataForAmazonPay,
  setupAmazonPay,
} from "@/utils/amazon";
import { sendError } from "@/utils/sentry";

export function getDefaultFormValues(
  amazonPayEnable: boolean,
  amazonPayCheckoutSessionId: string | null
): DeepPartial<PointPlanValues> {
  let paymentData: DeepPartial<PaymentDataValues> = {
    paymentMethod: PaymentMethod.credit,
  };

  if (amazonPayEnable) {
    paymentData = {
      paymentMethod: PaymentMethod.amazon,
      amazonCheckoutSessionId: amazonPayCheckoutSessionId || undefined,
    };
  }

  const formValues = getFormDataForAmazonPay<AmazonPayStorage>("amazon_pay_point_pack");
  const planType = formValues
    ? convertStorageFormValues(formValues).planType
    : PointProductPlan.lightSet;

  return {
    planType,
    paymentData,
  };
}

export async function gotoAmazon(values: PointPlanValues, pathname: string) {
  setFormDataForAmazonPay("amazon_pay_point_pack", convertFormValuesToStorage(values));
  await setupAmazonPay({
    reviewReturnPath: pathname,
    resultReturnPath: `${pathname}?confirm=true`,
    isSubscription: false,
  });
}

export async function gotoAmazonCheckout(
  formValues: PointPlanValues,
  pointProduct: PointProduct,
  pathname: string
) {
  const res = await getAmazonCheckoutUrl({
    amazonCheckoutSessionId: formValues.paymentData.amazonCheckoutSessionId!,
    amount: pointProduct.price.toString(),
    is_subscription: false,
    review_return_path: pathname,
    result_return_path: `${pathname}?confirm=true`,
  });

  if (res.url) {
    window.location.href = res.url;
  }

  if (res.error) {
    htmlToast.error(res.error);
  }

  return;
}

export async function gotoAmazonChangePayment(
  formValues: PointPlanValues,
  sessionId: string | null
) {
  if (sessionId) {
    setFormDataForAmazonPay("amazon_pay_point_pack", convertFormValuesToStorage(formValues));
    await fireAmazonPayAction(sessionId, "changePayment");
  }
}

export async function confirm(
  formValues: PointPlanValues,
  pointProduct: PointProduct
): Promise<boolean> {
  // クレカ払いの場合はstripeのトークンを取得し、バリデーションを実行
  if (
    formValues.paymentData.paymentMethod === PaymentMethod.credit &&
    !formValues.paymentData.stripeToken
  ) {
    htmlToast.error("クレジットカード情報を入力してください");
    return false;
  }

  return await validate(formValues, pointProduct);
}

function convertPointProductPlanToType(plan: PointProductPlanValue): PointProductType {
  switch (plan) {
    case PointProductPlan.lightSet:
      return PointProductType.LIGHT;
    case PointProductPlan.annualSet:
      return PointProductType.REGULAR;
    default:
      throw new Error("Invalid point product plan");
  }
}

function getPointApiRequestBody(pointProduct: PointProduct, formValues: PointPlanValues) {
  return {
    payment_data: {
      stripe_token: formValues.paymentData.stripeToken,
      last4: formValues.paymentData.last4,
      amazon_checkout_session_id: formValues.paymentData.amazonCheckoutSessionId,
    },
    payment_method: formValues.paymentData.paymentMethod,
    point_product: { ...pointProduct, type: convertPointProductPlanToType(formValues.planType) },
  };
}

async function validate(formValues: PointPlanValues, pointProduct: PointProduct) {
  try {
    const res = await postPointProductConfirm(getPointApiRequestBody(pointProduct, formValues));

    const exception = res.exception;
    if (exception && exception.length > 0) {
      exception.map((e) => htmlToast.error(e));
      return false;
    }

    return true;
  } catch (e) {
    htmlToast.error("エラーが発生しました");
    sendError(e);
    return false;
  }
}

export async function submit(formValues: PointPlanValues, pointProduct: PointProduct) {
  const productType = convertPointProductPlanToType(formValues.planType);
  try {
    fireKarteEvent("buy_yearplan", { type: productType });

    const res = await postPointProductCreate(getPointApiRequestBody(pointProduct, formValues));

    deleteFormDataForAmazonPay("amazon_pay_point_pack");

    return res;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      const error = e.response.data as PostPointProductCreate400;
      const reason = error.exception?.hasOwnProperty("reason")
        ? (error.exception as PostPointProductCreate400OneOfTwoException).reason
        : (error as PostPointProductCreate400OneOf).exception?.[0];
      htmlToast.error(reason || "エラーが発生しました");
    } else {
      htmlToast.error("エラーが発生しました");
    }

    sendError(e);
    return;
  }
}

type AmazonPayStorage = {
  productOptionsSelected: number;
};

// フィールド名がおかしいが、Vueに合わせるためこうなっている
const convertFormValuesToStorage = (values: PointPlanValues): AmazonPayStorage => {
  return {
    productOptionsSelected: values.planType === PointProductPlan.lightSet ? 0 : 1,
  };
};

const convertStorageFormValues = (values: AmazonPayStorage): DeepPartial<PointPlanValues> => {
  return {
    planType:
      values.productOptionsSelected === 0 ? PointProductPlan.lightSet : PointProductPlan.annualSet,
  };
};

const formId = "yearplanPurchase";

export const scrollToForm = () => {
  scroller.scrollTo(formId, {
    duration: 500,
    smooth: true,
  });
};
