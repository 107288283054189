import { ProductLargeImage } from "../../product/ProductLargeImage";

export function NewLaunchedSaltedCookies(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/basecookies/pepper"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_pepper_cheese_W1920_H1080_0805.jpg",
        alt: "大人も子供も楽しめるおつまみ＆おやつクッキー",
        aspectRatio: "1920/1080",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_pepper_cheese_W1080_H1920_0805.jpg",
        alt: "BASE Cookies ペッパー, BASE Cookies チーズ 新発売",
        aspectRatio: "1080/1920",
      }}
    />
  );
}
