import { StaticImageData } from "next/image";

import { NextImageResponsive } from "@/components/displays";

import styles from "./LineupSectionItemSp.module.scss";

export interface LineupSectionItemSpProps {
  category: string;
  title: string;
  imageSrc: StaticImageData;
  description: string;
}

export function LineupSectionItemSp({
  category,
  title,
  imageSrc,
  description,
}: LineupSectionItemSpProps): React.ReactNode {
  return (
    <dl className={styles.flavorLineup}>
      <dt className={styles.flavorLineupImage}>
        <NextImageResponsive src={imageSrc} alt={title} width={133} sizes="133px" />
      </dt>
      <dd className={styles.flavorLineupContent}>
        <p className="text__s text__bold text__white">
          BASE {category}
          <sup className="sup">
            <i className="far fa-registered fa-fw"></i>
          </sup>
        </p>
        <p className="text__m text__bold mg__bottom__s">{title}</p>
        <p className="text__m">{description}</p>
      </dd>
    </dl>
  );
}
