"use client";

import { useCallback, useState } from "react";

import { saveSurvey } from "@/generated/axios-functions/payseAPI";
import { getOrderEmail, clearOrderEmail } from "@/storage/clientSurveyOrderEmail";

import {
  SurveyAnswers,
  SurveyForm,
  SurveyFormProps,
  covertToApiSurveyAnswers,
} from "../SurveyForm";

export interface AfterPurchaseSurveyProps {
  questions: SurveyFormProps["questions"];
}

export function AfterPurchaseSurvey({ questions }: AfterPurchaseSurveyProps): React.ReactNode {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<SurveyAnswers>({});

  const handleSendSurveyAnswers = useCallback(async () => {
    // 送信処理
    setIsSubmitted(true);

    await saveSurvey({
      answers: covertToApiSurveyAnswers(answers),
      email: getOrderEmail() || "",
    });

    clearOrderEmail();
  }, [answers]);

  return isSubmitted ? (
    <div>アンケートにご協力ありがとうございました。</div>
  ) : (
    <>
      <SurveyForm onChange={setAnswers} questions={questions} />
      <div className="text__center">
        <button
          type="button"
          className="btn inline yellow round angle__right text__black text__m"
          onClick={handleSendSurveyAnswers}
        >
          回答する
        </button>
      </div>
    </>
  );
}
