import clsx from "clsx";
import Link from "next/link";

import { Image } from "@/components/displays";
import { CartBadge } from "@/components/domains";

import cartIcon from "$/images/header/cart.svg";
import helpIcon from "$/images/header/help.svg";
import mypageIcon from "$/images/header/mypage.svg";

import styles from "./PCHeader.module.scss";

interface PCHeaderProps {}

export function PCHeader({}: PCHeaderProps): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-12">
          <div className={styles.headerContainer}>
            <a className={styles.headerHome} href="https://basefood.co.jp">
              <Image
                src="https://asset.basefood.co.jp/images/logo/logo-color.svg"
                alt="ベースフード株式会社"
                size={{ width: 182, height: 30 }}
              />
            </a>

            <nav className={styles.navigation}>
              <ul className={styles.navMenu}>
                <li className={styles.navMenuCell}>
                  <Link
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="/products/subscription/2"
                  >
                    スタートセット
                  </Link>
                </li>
                <li className={styles.navMenuCell}>
                  <Link className={clsx(styles.navLink, "text__m text__bold")} href="/">
                    商品一覧
                  </Link>
                </li>
                <li className={styles.navMenuCell}>
                  <Link className={clsx(styles.navLink, "text__m text__bold")} href="/egift">
                    ギフト
                  </Link>
                </li>
                <li className={styles.navMenuCell}>
                  <a
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="https://basefood.co.jp/brand"
                  >
                    ブランドについて
                  </a>
                </li>
                <li className={styles.navMenuCell}>
                  <a
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="https://basefood.co.jp/safety"
                  >
                    安全安心への取り組み
                  </a>
                </li>
                <li>
                  <a
                    // ここだけボーダーを入れるデザインになっている
                    className={clsx(styles.navigationIcon, styles.navigationBorder)}
                    href="https://basefood.zendesk.com/hc/ja"
                    target="_blank"
                  >
                    <Image
                      src={helpIcon.src}
                      alt="ヘルプ"
                      size={{ width: 24, height: 24 }}
                      className={styles.navigationIconAlign}
                    />
                  </a>
                </li>
                <li>
                  <Link className={clsx(styles.navigationIcon)} href="/mypage">
                    <Image
                      src={mypageIcon.src}
                      alt="マイページ"
                      size={{ width: 24, height: 24 }}
                      className={styles.navigationIconAlign}
                    />
                  </Link>
                </li>
                <li>
                  <Link className={clsx(styles.navigationIcon)} href="/cart">
                    <CartBadge src={cartIcon.src} width={24} height={24} isPC={true} />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
