import { useState } from "react";

import clsx from "clsx";
import Link from "next/link";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { Button } from "@/components/inputs";
import { MileModel } from "@/models/mile/type";
import { SubscriptionResponseModel } from "@/models/subscription/type";
import rankStyles from "@/style/rankColor.module.scss";

import { NutrientPanel } from "./NutrientPanel";
import { SnsShareModal } from "./SnsShareModal";
import styles from "./StatusBox.module.scss";
import { RANK_MAP } from "../../settings";

interface StatusBoxProps {
  mile: MileModel;
  subscription: SubscriptionResponseModel;
}

export function StatusBox({ mile, subscription }: StatusBoxProps): React.ReactNode {
  const { calculatedNutrients, continuousMonths, continuousWeeks, rankName, totalMeals } = mile;
  const rank = RANK_MAP[rankName];

  const [isSnsShareModalOpen, setIsSnsShareModalOpen] = useState(false);

  return (
    <div className={clsx(styles.container, styles[`background${rankName}`])}>
      <div className={styles.rankContainer}>
        <div className={styles.flex}>
          <Row className={styles.rank}>
            <Image
              containerClassName={styles.rankImage}
              src={rank.rankImage}
              size={{ width: 100, height: 100 }}
              alt="ランク画像"
            />
            <Column className={styles.currentRank}>
              <p className={clsx("text__s mg__top__s", rankStyles[`${rankName}PrimaryFontColor`])}>
                現在のランク
              </p>
              <p className={clsx("text__l text__bold", rankStyles[`${rankName}PrimaryFontColor`])}>
                {rank.rankNameJp}
              </p>
              <Link
                href="/mileprogram"
                className={clsx(
                  "bg__white text__s text__bold mg__top__s",
                  styles.rankDetailLink,
                  rankStyles[`${rankName}PrimaryFontColor`]
                )}
              >
                ランクと特典をみる
              </Link>
            </Column>
          </Row>
        </div>

        {calculatedNutrients && (
          <NutrientPanel
            calculatedNutrients={calculatedNutrients}
            continuousMonths={continuousMonths}
            continuousWeeks={continuousWeeks}
            totalMeals={totalMeals}
            subscription={subscription}
            rankName={rankName}
          />
        )}
        <Row justify="end">
          <Button size="sm" rounded variants="gray" onClick={() => setIsSnsShareModalOpen(true)}>
            SNSで記録をシェアする
          </Button>
        </Row>
      </div>
      <SnsShareModal open={isSnsShareModalOpen} onClose={() => setIsSnsShareModalOpen(false)} />
    </div>
  );
}
