import { productNameSkuMap } from "@/configs/skuDB";

// TODO: 新商品かどうかは、APIから取得するべき
export const newProductNames = ["baseyakisoba_sauceyakisoba", "baseyakisoba_umakaramazesoba"];
export const limitedProductNames = ["basebread_strawberry"];

// TODO: 商品説明は、APIから取得するべき
export const subscriptionProductDescriptions = [
  {
    sku: productNameSkuMap.basebread_slice_plain,
    description: "朝食にもランチにも、やさしい甘みでアレンジ楽しい全粒粉ミニ食パン。",
  },
  {
    sku: productNameSkuMap.basebread_slice_raisin,
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },
  {
    sku: productNameSkuMap.basebread_plain,
    description: "全粒粉のやさしい甘み、やわもち食感の丸パン。ハンバーガーなどアレンジ自在。",
  },
  {
    sku: productNameSkuMap.basebread_rich,
    description: "ふっくらとした生地にまろやかな甘み広がる、芳醇なあじわいのロールパン。",
  },
  {
    sku: productNameSkuMap.basebread_koshian,
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    sku: productNameSkuMap.basebread_coffee,
    description: "グアテマラ産コーヒーの香り広がる大人のスティックパン。",
  },
  {
    sku: productNameSkuMap.basebread_milk,
    description: "北海道産牛乳のほのかな甘み広がるミルクパン。",
  },
  {
    sku: productNameSkuMap.basebread_strawberry,
    description: "【期間限定】あまおう苺のやさしい甘み広がるスティックパン。",
  },
  {
    sku: productNameSkuMap.basebread_chocolate,
    description: "しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。",
  },
  {
    sku: productNameSkuMap.basebread_maple,
    description: "まろやかな甘みにほっとするメープルパン。やわもち食感のスティックタイプ。",
  },
  {
    sku: productNameSkuMap.basebread_cinnamon,
    description: "本格シナモンが豊かに香るシナモンパン。やわもち食感のスティックタイプ。",
  },
  {
    sku: productNameSkuMap.basebread_curry,
    description: "やわもち生地に濃厚カレーがたっぷり入った、満足感のある惣菜パン。",
  },
  {
    sku: productNameSkuMap.basepasta_fettochine,
    description: "クリーム系など濃厚ソースにぴったり、もちもち食感の平打ち麺。ゆで時間2分。",
  },
  {
    sku: productNameSkuMap.basepasta_asian,
    description: "ペペロンチーノから焼きそばまで、アレンジ自在な細麺。ゆで時間1分。",
  },
  {
    sku: productNameSkuMap.sauce_set,
    description: "2種類の味がたのしめる4食セット（ボロネーゼ2食+まぜそば2食）をご用意しています。",
  },
  {
    sku: productNameSkuMap.basecookies_cocoa,
    description: "ココアの風味とサクサク食感のバランスが良い、落ち着く大人のクッキー。",
  },
  {
    sku: productNameSkuMap.basecookies_earlgrey,
    description: "アールグレイの香り引き立つ、気分ほぐれるクッキー。甘さひかえめ。",
  },
  {
    sku: productNameSkuMap.basecookies_matcha,
    description:
      "上質な抹茶にコクのある黒糖をあわせた、深みある大人の味わい。サクサク食感のクッキー。",
  },
  {
    sku: productNameSkuMap.basecookies_coconut,
    description:
      "粗挽きココナッツの風味と香りがひとくちごとに広がる、ほんのり甘いクッキー。サクサク食感。",
  },
  {
    sku: productNameSkuMap.basecookies_pepper,
    description: "お酒のお供に！ザクザク食感が特徴のピリッと香るブラックペッパー。",
  },
  {
    sku: productNameSkuMap.basecookies_cheese,
    description: "おやつにサクッと！ザクザク食感が特徴のやさしく香ばしいチェダーチーズ。",
  },
  {
    sku: productNameSkuMap.basepancake_pancakemix,
    description: "33種の栄養素入り。ふんわりしっとり感が楽しめるパンケーキミックス。",
  },
  {
    sku: productNameSkuMap.basepasta_bolognese,
    description:
      "香味野菜のうまみと、ゴロゴロお肉が麺にからみあう、濃厚贅沢パスタ。たんぱく質36.7g入り。",
  },
  {
    sku: productNameSkuMap.basepasta_tarako,
    description:
      "「“焼き”たらこ」のプチプチした食感と「“生”たらこ」の濃厚な味わいを一度に楽しむことのできる、たっぷりたらこの旨みがギュッとつまったパスタ。たんぱく質27g入り。",
  },
  {
    sku: productNameSkuMap.basepasta_creamymushroom,
    description:
      "3種のきのこの豊かな風味と濃厚なホワイトソースがもちもち麺に絡み合うクリームパスタ。たんぱく質27g入り。",
  },
  {
    sku: productNameSkuMap.baseyakisoba_sauceyakisoba,
    description:
      "懐かしく香ばしいソースがつるつるもちもち麺とよく絡む、全粒粉ベースのソース焼きそば。",
  },
  {
    sku: productNameSkuMap.baseyakisoba_umakaramazesoba,
    description:
      "つるつるもちもち麺がスパイスの風味と旨味を引き立てる、汁なしタイプの旨辛まぜそば。",
  },
];

type SubscriptionProductCaution = { sku: string; cautions: string[] };
export const subscriptionProductCautions: SubscriptionProductCaution[] = [
  {
    sku: productNameSkuMap.baseyakisoba_sauceyakisoba,
    cautions: [
      `※旧マイルプログラムでゴールド以上だった方は、現在のランクに関わらず新商品30%オフ特典が適用されます。`,
    ],
  },
  {
    sku: productNameSkuMap.baseyakisoba_umakaramazesoba,
    cautions: [
      `※旧マイルプログラムでゴールド以上だった方は、現在のランクに関わらず新商品30%オフ特典が適用されます。`,
    ],
  },
];
